  
  
  .wrapper input[type="text"] {
      position: relative;
      padding-left: 5px;
  }

        
  .wrapper:before {
      font-family: 'FontAwesome';
      color: #17304e;
      position: relative;
      left: 30px;
      top: 2px;
      content: 'Welcome';
      font-size: 20px;
  }
.union-jack-wrapper { position: relative; }
.union-jack-wrapper img { position: absolute; top: 6px; left: 6px; }